import React, { CSSProperties } from "react";

const playerCardStyle: CSSProperties = {
  border: "2px solid #333",
  padding: "10px",
  width: "175px",
  height: "175px",
  backgroundColor: "#ffffff",
  boxShadow: "0px 0px 5px #666",
  cursor: "pointer",
  transition:
    "background-color 0.3s" /* Smooth transition for background color change */,
  borderRadius: "5%",
  // animation: "bounce 2s ease-in-out infinite",
};

export function PlayerCard(props: any) {
  const randomDelay = (Math.random() * 2).toFixed(2);

  return (
    <>
      <div style={{ ...playerCardStyle, animationDelay: `${randomDelay}s` }}>
        {props.children}
      </div>
    </>
  );
}
