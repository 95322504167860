import React, { useState, ChangeEvent } from "react";
import ChatGPTApi from "../../services/ChatGptApi";
import BoxScoreResponse, { TeamStats } from "./BoxScoreResponse";
import { Player } from "./PlayersToChooseFrom";
import Loading from "../../components/Loading/Loading";
import { useNavigate } from "react-router-dom";

interface GptTextComponentProps {
  selectedPlayers: Player[];
  teamToBeat: Player[];
  setIsSimulating: Function;
  handleResponseTextChange: (newResponseText: TeamStats | undefined) => void;
}

function GptTextComponent({
  selectedPlayers,
  teamToBeat,
  setIsSimulating,
  handleResponseTextChange,
}: GptTextComponentProps) {
  const [responseText, setResponseText] = useState<TeamStats | undefined>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChatRequest = async () => {
    if (selectedPlayers.length < 5) {
      alert("Select a player from each position");
      return;
    }

    const playersString = selectedPlayers
      .map(
        (player) =>
          `Name: ${player.name} Position: (${player.position}), Price: $${player.price}`
      )
      .join(" | ");
    const teamToBeatString = teamToBeat
      .map((team) => `Name:${team.name}, Position:${team.position}`)
      .join(" | ");

    setLoading(true);
    setIsSimulating(true); // used in parent component, App.tsx
    const response = await ChatGPTApi(playersString, teamToBeatString);
    setResponseText(response);
    handleResponseTextChange(response);
    setLoading(false);
    setIsSimulating(false);
  };

  return (
    <div>
      <div>
        {loading ? (
          <div>
            <Loading
              selectedPlayers={selectedPlayers}
              teamToBeat={teamToBeat}
            />
          </div>
        ) : responseText ? (
          <div>
            <BoxScoreResponse data={responseText} />
            <button
              className="submit-button"
              onClick={() => window.location.reload()}
            >
              Play Again
            </button>
          </div>
        ) : (
          <button className="submit-button" onClick={handleChatRequest}>
            Start
          </button>
        )}
      </div>
    </div>
  );
}

export default GptTextComponent;
