import React, { useEffect, useState, useRef } from "react";
import { createRef } from "react";
import { CSVLink } from "react-csv";

import axios from "axios";
import { Button, Form, Table } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import {
  auth,
  db,
  getTeamName,
  logout,
  refreshLeaderboard,
  updateDailyTeamChallengePlayers,
  updateTeamToBeatName,
  uploadAvailablePlayers,
} from "../../services/firestore";

import Papa from "papaparse";

const playerPicksCsvTemplate = [["Name", "Price", "Position"]];
const teamToBeatCsvTemplate = [["Name", "Price", "Position"]];

export function AdminPortal() {
  const [user, loading, error] = useAuthState(auth);
  const [email, setEmail] = useState("");
  const [teamName, setTeamName] = useState("");

  const teamToBeatRef = createRef<any>();
  const dailyPlayersRef = createRef<any>();
  const teamNameRef = createRef<any>();

  const navigate = useNavigate();

  const fetchUserName = async () => {
    try {
      // const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      // console.log(q);
      // const doc = await getDocs(q);
      // console.log(doc);
      // const data = doc.docs[0].data();
      // setName(data.name);
      setEmail(user?.email || "");
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  const getCurrentTeamName = async () => {
    const teamName = await getTeamName();
    setTeamName(teamName);
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchUserName();
    getCurrentTeamName();
  }, [user, loading]);

  const resetLeaderboard = async () => {
    if (window.confirm("Are you sure?")) {
      refreshLeaderboard();
    }
  };

  const onSave = async (e: any) => {
    e.preventDefault();
    console.log("saving...");

    if (teamNameRef.current.value !== "") {
      const response = (await updateTeamToBeatName({
        data: teamNameRef.current.value,
      })) as any;

      if (response.status === 200) {
        setTeamName(teamNameRef.current.value);
      }
    }

    if (teamToBeatRef.current.files.length > 0) {
      parseData(teamToBeatRef, updateDailyTeamChallengePlayers);
    }

    if (dailyPlayersRef && dailyPlayersRef.current.files.length > 0) {
      parseData(dailyPlayersRef, uploadAvailablePlayers);
    }
  };

  const parseData = (fileRef: any, action: any) => {
    Papa.parse(fileRef.current.files[0], {
      header: true,
      complete: async (results: any) => {
        let stringifyData = results.data
          .map((row: any) => Object.values(row).join(","))
          .join("\n");

        await action({ data: stringifyData });
      },
    });
  };

  return (
    <div style={{ margin: "10px" }}>
      <div style={{ marginBottom: "10px" }}>
        Signed in: {email}
        {"            "}
        <button className="btn btn-secondary" onClick={logout}>
          Sign out
        </button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        <Table bordered hover>
          <thead>
            <tr>
              <th>Team to beat (CURRENT NAME: {teamName}) </th>
              <th>
                Upload Daily Player{" "}
                <CSVLink filename="daily-players" data={playerPicksCsvTemplate}>
                  {" "}
                  Download
                </CSVLink>
              </th>
              <th>
                Upload Team to Beat{" "}
                <CSVLink filename="teams-to-beat" data={teamToBeatCsvTemplate}>
                  {" "}
                  Download
                </CSVLink>
              </th>
              <th>Leaderboard</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Form.Control type="text" ref={teamNameRef} />
              </td>
              <td>
                <Form.Control type="file" ref={dailyPlayersRef} accept=".csv" />
              </td>
              <td>
                <Form.Control type="file" ref={teamToBeatRef} accept=".csv" />
              </td>
              <td>
                <Button variant="danger" onClick={resetLeaderboard}>
                  Reset
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <button className={"btn btn-primary"} onClick={onSave}>
        Save
      </button>
    </div>
  );
}
