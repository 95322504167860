import React, { useRef, useState, useEffect, useCallback } from "react";
import Button from "react-bootstrap/Button";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../services/firestore";
import { AvailablePlayers } from "../../components/Leaderboard";
import { Form, Row, Table } from "react-bootstrap";
import "./styles.css";
import ReactCanvasConfetti from "react-canvas-confetti";
import Confetti from "react-canvas-confetti";

// Example TeamStats type
type PlayerStats = {
  player_name: string;
  "3PTM": number;
  "3PTA": number;
  FGM: number;
  FGA: number;
  FTM: number;
  FTA: number;
  points: number;
  rebounds: number;
  assists: number;
  steals: number;
  blocks: number;
  turnovers: number;
  fouls: number;
  price: number;
  position: string;
};

export type TeamStats = {
  team1: string;
  team2: string;
  team1_score: number;
  team2_score: number;
  description_of_game: string;
  highlights_of_the_game: string[];
  notable_plays_of_the_game: string[];
  players_team1: PlayerStats[];
  players_team2: PlayerStats[];
};

interface BoxScoreProps {
  data: TeamStats;
}

const BoxScoreResponse: React.FC<BoxScoreProps> = ({ data }) => {
  const [teamNameInput, setTeamNameInput] = useState("");
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const refAnimationInstance = useRef<Confetti | null>(null);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTeamNameInput(e.target.value);
  };

  const handleSubmission = async () => {
    if (teamNameInput.trim() !== "") {
      const score = data.team2_score - data.team1_score;
      const playersTeam2Converted: AvailablePlayers[] = data.players_team2.map(
        (player) => ({
          name: player.player_name,
          price: player.price || 0,
          position: player.position || "",
        })
      );
      await writeToLeaderboard(teamNameInput, score, playersTeam2Converted);
      setSubmissionSuccess(true);
    }
  };

  async function writeToLeaderboard(
    alias: string,
    score: number,
    lineup: AvailablePlayers[]
  ) {
    console.log(alias, score, lineup);
    await addDoc(collection(db, "leaderboard"), {
      alias,
      score,
      lineup,
    });
  }

  const getInstance = useCallback((instance: any) => {
    refAnimationInstance.current = instance;
  }, []);

  // function fire() {
  //   refAnimationInstance.confetti();
  // }

  // useEffect(() => {
  //   fire();
  // }, []);

  return (
    <div className="box-score">
      {/* <ReactCanvasConfetti refConfetti={getInstance} className="canvas" /> */}

      <div className="results">
        <div className="teams">
          <h2>
            {data.team1} vs. {data.team2}
          </h2>
        </div>
        <div className="score">
          {data.team1_score}
          <span
            style={{
              fontSize: 15,
              fontWeight: 200,
              padding: "20px",
            }}
          >
            {" "}
            FINAL{" "}
          </span>
          {data.team2_score}
        </div>
        <div className="result-text">
          {data.team2_score > data.team1_score ? (
            <span>You Won! 🎉</span>
          ) : (
            <span>You lost! 😢</span>
          )}
        </div>
      </div>
      {submissionSuccess ? (
        <p style={{ color: "green" }}>Submission successful!</p>
      ) : (
        <div className="lb-container">
          <div className="lb-input">
            <Form.Group>
              <Form.Control
                ref={inputRef}
                type="text"
                placeholder="Enter Team Name"
                value={teamNameInput}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Button
              onClick={handleSubmission}
              disabled={teamNameInput.trim() === ""}
              className="m-2"
            >
              Submit To Leaderboard
            </Button>
          </div>
        </div>
      )}
      <div className="content-body">
        <div className="content">
          <ul>
            <li className="mb-4">
              <strong className="content-header">Summary.</strong>
              <br />
              <p className="m-1">{data.description_of_game}</p>
            </li>
            <li className="mb-4">
              <strong className="content-header">
                Highlights of the Game.
              </strong>
              <ul className="m-1">
                {data.highlights_of_the_game.map((highlight, index) => (
                  <li key={index}>{highlight}</li>
                ))}
              </ul>
            </li>
            <li className="mb-4">
              <strong className="content-header">
                Notable Plays of the Game.
              </strong>
              <ul className="m-1">
                {data.notable_plays_of_the_game.map((play, index) => (
                  <li key={index}>{play}</li>
                ))}
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div className="team">
        <h3>{data.team1}</h3>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Player</th>
              <th>3PTM</th>
              <th>3PTA</th>
              <th>FGM</th>
              <th>FGA</th>
              <th>FTM</th>
              <th>FTA</th>
              <th>Points</th>
              <th>Rebounds</th>
              <th>Assists</th>
              <th>Steals</th>
              <th>Blocks</th>
              <th>Turnovers</th>
              <th>Fouls</th>
            </tr>
          </thead>
          <tbody>
            {data.players_team1.map((player, index) => (
              <tr key={index}>
                <td>{player.player_name}</td>
                <td>{player["3PTM"]}</td>
                <td>{player["3PTA"]}</td>
                <td>{player.FGM}</td>
                <td>{player.FGA}</td>
                <td>{player.FTM}</td>
                <td>{player.FTA}</td>
                <td>{player.points}</td>
                <td>{player.rebounds}</td>
                <td>{player.assists}</td>
                <td>{player.steals}</td>
                <td>{player.blocks}</td>
                <td>{player.turnovers}</td>
                <td>{player.fouls}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="team2">
        <h3>{data.team2}</h3>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Player</th>
              <th>3PTM</th>
              <th>3PTA</th>
              <th>FGM</th>
              <th>FGA</th>
              <th>FTM</th>
              <th>FTA</th>
              <th>Points</th>
              <th>Rebounds</th>
              <th>Assists</th>
              <th>Steals</th>
              <th>Blocks</th>
              <th>Turnovers</th>
              <th>Fouls</th>
            </tr>
          </thead>
          <tbody>
            {data.players_team2.map((player, index) => (
              <tr key={index}>
                <td>{player.player_name}</td>
                <td>{player["3PTM"]}</td>
                <td>{player["3PTA"]}</td>
                <td>{player.FGM}</td>
                <td>{player.FGA}</td>
                <td>{player.FTM}</td>
                <td>{player.FTA}</td>
                <td>{player.points}</td>
                <td>{player.rebounds}</td>
                <td>{player.assists}</td>
                <td>{player.steals}</td>
                <td>{player.blocks}</td>
                <td>{player.turnovers}</td>
                <td>{player.fouls}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default BoxScoreResponse;
