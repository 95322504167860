import React from "react";
import { PlayerCard } from "../../components/PlayerCard/PlayerCard";
import { Player } from "./PlayersToChooseFrom";

interface TeamToBeatListProps {
  teamsToBeat: Player[];
}

// todo: rename to TeamLineup and move to components
const TeamToBeatList: React.FC<TeamToBeatListProps> = ({ teamsToBeat }) => {
  return (
    <div>
      <div className="player-card-container">
        {teamsToBeat.map((team, index) => (
          <PlayerCard key={team.id}>
            <h3>{team.name}</h3>
            <p>
              <strong>Position:</strong> {team.position}
            </p>
            {/*<p><strong>Price:</strong> ${team.price}</p>*/}
          </PlayerCard>
        ))}
      </div>
    </div>
  );
};

export default TeamToBeatList;