import {
  collection,
  getDocs,
  limit,
  query,
  orderBy,
  addDoc,
} from "firebase/firestore";
import { db } from "../services/firestore";
import { useState, useEffect } from "react";

import Modal from "react-bootstrap/Modal";
import { Table } from "react-bootstrap";

export interface AvailablePlayers {
  name: string;
  price: number;
  position: string;
}

function Leaderboard({ show, onHide }: any) {
  const [leaderboard, setLeaderboard] = useState([] as any[]);

  async function getLeaderboard() {
    const constraints = [orderBy("score", "desc"), limit(100)];
    const leaderboardRef = collection(db, "leaderboard");

    const leaderboardSnapshot = await getDocs(
      query(leaderboardRef, ...constraints)
    );
    const leaderboard = leaderboardSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    setLeaderboard(leaderboard);
  }

  useEffect(() => {
    getLeaderboard();
  }, []);
  console.log(leaderboard);

  // todo: separate leaderboard and modal two different components
  return (
    <>
      <Modal show={show} fullscreen={true} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Leaderboard</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Alias</th>
                  <th>Score</th>
                  {/*<th>Lineup</th>*/}
                </tr>
              </thead>
              <tbody>
                {leaderboard.map((entry, index) => (
                  <tr key={entry.id}>
                    <td width={"100px"}>{index + 1}</td>
                    <td>{entry.alias}</td>
                    <td>{entry.score}</td>
                    {/*  <td>*/}
                    {/*  <ul>*/}
                    {/*{entry.lineup.map((player:AvailablePlayers) => (*/}
                    {/*  <li key={player.name}>*/}
                    {/*{player.name} ({player.position}): ${player.price}*/}
                    {/*  </li>*/}
                    {/*  ))}*/}
                    {/*  </ul>*/}
                    {/*  </td>*/}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Leaderboard;
