import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Leaderboard from "../../components/Leaderboard";
import Button from "react-bootstrap/Button";

export function Topbar() {
  const navigate = useNavigate();
  const [showLeaderboard, setShowLeaderboard] = useState(false);

  function navToLeaderboard() {
    navigate("/leaderboard");
  }

  return (
    <>
      <Navbar style={{'padding':'10px', 'backgroundColor': '#f5f5f5'}}>
        <Navbar.Brand>HoopsGPT</Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <Button
              variant="secondary"
              onClick={() => setShowLeaderboard(true)}
          >
            Leaderboard
          </Button>
        </Navbar.Collapse>
      </Navbar>
      <Leaderboard
        show={showLeaderboard}
        onHide={() => setShowLeaderboard(false)}
      />
    </>
  );
}
