import React, { useState, useEffect } from "react";
import { Player } from "../../scenes/home/PlayersToChooseFrom";
import "./styles.css";
import { Container, Nav, Navbar } from "react-bootstrap";

export function Loading({ selectedPlayers, teamToBeat }: any) {
  const [texts, setTexts] = useState(["Simulating game...", "Please wait..."]);

  const [textIndex, setTextIndex] = useState(0);
  const [currentText, setCurrentText] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setCurrentText(texts[textIndex]);
  }, [textIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      populatText();
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  function populatText() {
    if (!selectedPlayers || !teamToBeat) return;
    const randomPlayer =
      selectedPlayers[Math.floor(Math.random() * selectedPlayers.length - 1)]
        ?.name;
    const randomToBeat =
      teamToBeat[Math.floor(Math.random() * teamToBeat.length - 1)]?.name;

    console.log(randomPlayer, randomToBeat);
    if (randomPlayer && randomToBeat) {
      const other = [
        `Simulating ${randomPlayer} vs. ${randomToBeat}...`,
        `Simulating ${randomPlayer} battling ${randomToBeat} in the paint...`,
        `Simulating ${randomPlayer} dueling against ${randomToBeat}...`,
      ];

      setTexts([...other, "Simulating game...", "Please wait..."]);
    }
  }

  return (
    <>
      <Container>
        <div className="waveform">
          <div className="waveform__bar"></div>
          <div className="waveform__bar"></div>
          <div className="waveform__bar"></div>
          <div className="waveform__bar"></div>
        </div>
        <div>
          <p>(Please don't refresh or leave page. Can take up to 5 mins)</p>
        </div>
        <div className="loading">
          <p className="loading-text">{currentText}</p>
        </div>
      </Container>
    </>
  );
}

export default Loading;
