import axios, { AxiosResponse, AxiosError } from "axios";
import { TeamStats } from "../scenes/home/BoxScoreResponse";
import { collection, getDocs, query } from "firebase/firestore";
import { db, getTeamName } from "../services/firestore";

type ChatResponse = {
  choices: Array<{
    message: {
      content: string;
    };
  }>;
};

const ChatGPTApi = async (
  inputText: string,
  teamToBeat: string
): Promise<TeamStats | undefined> => {
  const apiKey = "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";
  const apiUrl = "https://api.openai.com/v1/chat/completions";

  const firstTeamName = await getTeamName();
  const messageContent = `Create a realistic NBA box score for a game between two imaginary teams in a table that has 3PTM, 3PA, FGM, FGA, FTM, FTA, points, rebounds, assists, steals, blocks, turnovers, fouls, price, and position. Take into account how these players would have fared playing together and against the other team based on their historic statistics.
First team is called "${firstTeamName}" and the starting lineup consists of:
${teamToBeat}
all in their prime. Fill the rest of the team's roster with random NBA players throughout NBA history that were average.
The second team will be in the user's input and they will all be in their prime as well. Expect 5 players.

Return the response in JSON with a structure like this:
type PlayerStats = {
    player_name: string;
    '3PTM': number;
    '3PTA': number;
    FGM: number;
    FGA: number;
    FTM: number;
    FTA: number;
    points: number;
    rebounds: number;
    assists: number;
    steals: number;
    blocks: number;
    turnovers: number;
    fouls: number;
    price: number;
    position: string;
};

type TeamStats = {
    team1: string;
    team2: string;
    team1_score: number;
    team2_score: number;
    description_of_game: string;
    highlights_of_the_game: string[];
    notable_plays_of_the_game: string[];
    players_team1: PlayerStats[];
    players_team2: PlayerStats[];
};

In description_of_game, give me a summary of the game including the final score, notable performances, highlights in the game, and the player of the game. Also, add highlights_of_the_game and notable_plays_of_the_game and format them as bullet points.`;
  try {
    const response: AxiosResponse<ChatResponse> = await axios.post(
      apiUrl,
      {
        model: "gpt-4-1106-preview",
        messages: [
          {
            role: "system",
            content: messageContent,
          },
          {
            role: "user",
            content: inputText,
          },
        ],
      },
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
        },
      }
    );

    console.log(`System prompt: ${response}`);
    console.log(`User prompt: ${inputText}`);
    const content = response.data.choices[0].message.content;

    const startIndex = content.indexOf("{");
    const endIndex = content.lastIndexOf("}") + 1;

    // Extract the JSON object
    const jsonSubstring = content.substring(startIndex, endIndex);

    // Parse the extracted JSON
    const parsedContent = JSON.parse(jsonSubstring);

    console.log(parsedContent);
    return parsedContent;
  } catch (error) {
    const axiosError = error as AxiosError;
    console.error("API request failed:", axiosError);
    alert(`An error occurred please try again`);
  }
};

export default ChatGPTApi;
