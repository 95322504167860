import "./App.css";
import GptTextComponent from "./scenes/home/GptTextComponent";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./services/firestore";
import { useState, useEffect } from "react";
import TeamToBeatList from "./scenes/home/TeamToBeat";
import PlayersToChooseFrom, { Player } from "./scenes/home/PlayersToChooseFrom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Topbar } from "./scenes/global/TopBar";
import { TeamStats } from "./scenes/home/BoxScoreResponse";

const MAX_PRICE = 10;

function App() {
  const [availablePlayers, setAvailablePlayers] = useState([] as any[]);
  const [teamToBeat, setTeamToBeat] = useState([] as any[]);
  const [selectedPlayers, setSelectedPlayers] = useState<Player[]>([]);
  const [responseText, setResponseText] = useState<TeamStats | undefined>();

  const [isSimulating, setIsSimulating] = useState(false);

  async function getAvailableDailyPlayers() {
    const playersSnapshot = await getDocs(collection(db, "availablePlayers"));
    const playersList = playersSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setAvailablePlayers(playersList);
  }

  async function getDailyChallengePlayers() {
    const playersSnapshot = await getDocs(
      collection(db, "dailyChallengePlayers")
    );
    const playersList = playersSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setTeamToBeat(playersList);
  }

  useEffect(() => {
    getAvailableDailyPlayers();
    getDailyChallengePlayers();
  }, []);

  const handlePlayerToggle = (player: Player) => {
    // Check if the player is already selected
    const isPlayerSelected = selectedPlayers.some((p) => p.id === player.id);

    // Calculate the new total price if this player is selected
    const newTotalPrice = isPlayerSelected
      ? selectedPlayers.reduce((total, p) => total + p.price, 0) - player.price
      : selectedPlayers.reduce((total, p) => total + p.price, 0) + player.price;

    // Don't select the player if it exceeds the maximum price
    if (!isPlayerSelected && newTotalPrice > MAX_PRICE) {
      return;
    }

    // Create a new list of updated selected players
    const updatedSelectedPlayers = isPlayerSelected
      ? selectedPlayers.filter((p) => p.id !== player.id) // Remove the player
      : [...selectedPlayers, player]; // Add the player

    // Update the state with the updated list
    setSelectedPlayers(updatedSelectedPlayers);
  };

  // Callback function to receive and handle responseText
  const handleResponseTextChange = (newResponseText: TeamStats | undefined) => {
    setResponseText(newResponseText);
  };

  return (
    <div className="App">
      <Topbar />
      {!responseText && (
        <>
          <h2 style={{ marginTop: "40px" }}>You're up against... 😈</h2>
          <TeamToBeatList teamsToBeat={teamToBeat} />
          <br />
          {isSimulating && (
            <>
              <h2>Your lineup! 💪</h2>
              <TeamToBeatList teamsToBeat={selectedPlayers} />
            </>
          )}

          {!isSimulating && (
            <PlayersToChooseFrom
              players={availablePlayers}
              handlePlayerToggle={handlePlayerToggle}
              selectedPlayers={selectedPlayers}
              maxPrice={MAX_PRICE}
            />
          )}
          <br />
        </>
      )}
      <GptTextComponent
        handleResponseTextChange={handleResponseTextChange}
        selectedPlayers={selectedPlayers}
        teamToBeat={teamToBeat}
        setIsSimulating={setIsSimulating}
      />
    </div>
  );
}

export default App;
