import React from "react";

export interface Player {
  name: string;
  position: string;
  price: number;
  id: string;
}

interface PlayerListProps {
  players: Player[];
  handlePlayerToggle: (player: Player) => void;
  selectedPlayers: Player[];
  maxPrice: number;
}

const PlayersToChooseFrom: React.FC<PlayerListProps> = ({
  players,
  handlePlayerToggle,
  selectedPlayers,
  maxPrice,
}) => {
  const isPlayerSelected = (player: Player) => {
    return selectedPlayers.some(
      (selectedPlayer) => selectedPlayer.id === player.id
    );
  };

  const remainingPrice =
    maxPrice - selectedPlayers.reduce((total, p) => total + p.price, 0);

  // Separate players by position
  const separatedPlayers = players.reduce((acc, player) => {
    acc[player.position] = acc[player.position] || [];
    acc[player.position].push(player);
    return acc;
  }, {} as { [position: string]: Player[] });

  // Sort each position by price (highest to lowest)
  for (const position in separatedPlayers) {
    separatedPlayers[position].sort((a, b) => b.price - a.price);
  }
  return (
    <section>
      <h1>
        Choose your lineup! 🥊 <br /> (<strong>Budget:</strong> $
        {remainingPrice})
      </h1>
      <div className={"player-position-container"}>
        {Object.entries(separatedPlayers).map(([position, players]) => (
          <div key={position}>
            <h3>{position}</h3>
            <ul className="player-list">
              {players.map((player) => (
                <li key={player.id}>
                  <div
                    className={`player-item ${
                      isPlayerSelected(player) ? "selected" : ""
                    } ${
                      selectedPlayers.some(
                        (x) => x.position === player.position
                      )
                        ? "disabled"
                        : ""
                    }`}
                    onClick={() => {
                      if (
                        !selectedPlayers.some(
                          (x) => x.position === player.position
                        ) ||
                        isPlayerSelected(player)
                      ) {
                        handlePlayerToggle(player);
                      }
                    }}
                  >
                    <p>{player.name}</p>
                    <p>
                      <strong>${player.price}</strong>
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      {selectedPlayers.reduce((total, p) => total + p.price, 0) > maxPrice && (
        <p style={{ color: "red" }}>Maximum price exceeded!</p>
      )}
    </section>
  );
};

export default PlayersToChooseFrom;
