import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, query } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import axios from "axios";

const firebaseConfig = {
  apiKey: "AIzaSyB8rOGoC4nCosvy7Fuen7gmL6VFkeQu0Lk",
  authDomain: "stakes-ai-poc.firebaseapp.com",
  projectId: "stakes-ai-poc",
  storageBucket: "stakes-ai-poc.appspot.com",
  measurementId: "G-1K8WCZR7EJ",
  messagingSenderId: "569258109551",
  appId: "1:569258109551:web:162d8ee9a0b9bc837ffb9a",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const functions = getFunctions(app);
connectFunctionsEmulator(functions, "127.0.0.1", 5001);

const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

const uploadAvailablePlayers = async (body: any) => {
  try {
    const url = "https://uploaddailyavailableplayers-snnggqlcxq-uc.a.run.app";
    const response = await axios.post(url, body.data, {
      headers: {
        "Content-Type": "text/plain",
      },
    });
    alert("Available players updated successfuly!");
    return response;
  } catch (e: any) {
    console.log(e);
    alert("something went wrong");
  }
};

const updateDailyTeamChallengePlayers = async (body: any) => {
  try {
    const url =
      "https://updatedailyteamchallengeplayers-snnggqlcxq-uc.a.run.app";
    const response = await axios.post(url, body.data, {
      headers: {
        "Content-Type": "text/plain",
      },
    });
    alert("Daily team challenge updated!");
    return response;
  } catch (e) {
    console.log(e);
    alert("something went wrong");
  }
};

const updateTeamToBeatName = async (body: any) => {
  console.log(body);
  try {
    const url = "https://updateteamtobeatname-snnggqlcxq-uc.a.run.app";
    const response = await axios.post(url, body.data, {
      headers: {
        "Content-Type": "text/plain",
      },
    });
    alert("Team name updated successfully!");
    return response;
  } catch (e) {
    console.log(e);
    alert("something went wrong");
  }
};

const getTeamName = async () => {
  const teamNameRef = collection(db, "teamToBeatName");

  const leaderboardSnapshot = await getDocs(query(teamNameRef));

  const teamName = leaderboardSnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  })) as { name: string; id: string }[];

  return teamName[0].name ?? "'92 Dream Team";
};

const refreshLeaderboard = async () => {
  await axios.post("https://refreshleaderboard-snnggqlcxq-uc.a.run.app");
};

export {
  auth,
  db,
  logInWithEmailAndPassword,
  logout,
  uploadAvailablePlayers,
  updateDailyTeamChallengePlayers,
  updateTeamToBeatName,
  getTeamName,
  refreshLeaderboard,
};
